


























































































































































































































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { LogItem } from './types'
import { getToken } from '@/utils/utils'

@Component({ name: 'ScaManage' })
export default class LogManage extends VueBase {
  path = process.env.VUE_APP_BASE_API
  token = getToken()
  tableData = []
  pageSize = 20
  currentPage = 1
  total = 0
  keyword = ''

  form = {
    id: 0,
    group_id: '',
    atrifact_id: '',
    version: '',
    sha_1: '',
    package_name: '',
    license: '',
  }
  sca_count = 0
  vuln_count = 0

  public beforeUpload(file: any) {
    const isJPG = file.type === 'text/csv'

    if (!isJPG) {
      this.$message.error(this.$t('views.changeLogo.warningPng') as string)
      return false
    }

    return isJPG
  }

  public handleSuccess(res: any) {
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.clearForm()
      this.getTableData()
    } else {
      this.$message.error(res.msg)
    }
  }

  async sca_stat() {
    const res = await this.services.setting.sca_stat()
    if (res.status === 201) {
      this.sca_count = res.data.sca_count
      this.vuln_count = res.data.vuln_count
    } else {
      this.$message.error(res.msg)
    }
  }

  scaEditDialog = false

  multipleDelete() {
    if (this.multipleSelection.length === 0) {
      this.$message.error(this.$t('views.scaManage.selectWarning') as string)
      return
    }
    this.$confirm(
      `${this.$t('views.scaManage.changeOne')}${this.$t(
        'views.scaManage.changeTwo'
      )}`,
      this.$t('views.scaManage.pop') as string,
      {
        confirmButtonText: this.$t('views.scaManage.enter') as string,
        cancelButtonText: this.$t('views.scaManage.clear') as string,
        type: 'warning',
      }
    )
      .then(async () => {
        const ids = this.multipleSelection.map((item: any) => item.id)
        const res = await this.services.setting.delete_sca_bulk({ ids: ids })
        if (res.status !== 201) {
          this.$message({
            type: 'error',
            message: res.msg,
            showClose: true,
          })
          return
        }
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
        await this.getTableData()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  openAdd() {
    this.form = {
      id: 0,
      group_id: '',
      atrifact_id: '',
      version: '',
      sha_1: '',
      package_name: '',
      license: '',
    }
    this.scaDialog = true
  }

  async enterAdd() {
    if (this.activeName !== 'add') {
      this.clearForm()
      return
    }
    const res = await this.services.setting.add_sca(this.form)
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.clearForm()
      this.getTableData()
      return
    }
    this.$message.error(res.msg)
  }

  editRow(row: any) {
    this.form = JSON.parse(JSON.stringify(row))
    this.scaEditDialog = true
  }

  async deleteRow(row: any) {
    const res = await this.services.setting.delete_sca(row)
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.getTableData()
      return
    }
    this.$message.error(res.msg)
  }

  clearForm() {
    this.scaEditDialog = false
    this.scaDialog = false
    this.form = {
      id: 0,
      group_id: '',
      atrifact_id: '',
      version: '',
      sha_1: '',
      package_name: '',
      license: '',
    }
  }

  async enterEdit() {
    const res = await this.services.setting.edit_sca(this.form)
    if (res.status === 201) {
      this.clearForm()
      this.$message.success(res.msg)
      this.getTableData()
      return
    }
    this.$message.error(res.msg)
  }

  handleSizeChange(val: number) {
    this.pageSize = val
    this.getTableData()
  }

  handleCurrentChange(val: number) {
    this.currentPage = val
    this.getTableData()
  }

  multipleSelection = []
  handleSelectionChange(val: any) {
    this.multipleSelection = val
  }

  scaDialog = false

  name = ''
  activeName = 'add'

  license_list = []
  async get_license_list() {
    const res = await this.services.setting.get_license_list()
    if (res.status === 201) {
      this.license_list = res.data
      return
    }
    this.$message.error(res.msg)
  }

  download() {
    window.open(
      `${process.env.VUE_APP_BASE_API}/scadb/maven/template/maven_sca`
    )
  }

  created() {
    this.sca_stat()
    this.get_license_list()
    this.getTableData()
  }

  async getTableData() {
    this.loadingStart()
    const res = await this.services.setting.get_sca_strategy({
      page: this.currentPage,
      page_size: this.pageSize,
      name: this.keyword,
    })
    this.loadingDone()
    if (res.status === 201) {
      this.tableData = res.data
      this.total = res.page.alltotal
      return
    }
    this.$message.error(res.msg)
  }
}
